import {
  Button,
  Icon,
  LoadingSpinner,
  TextLink,
  Tooltip,
  Typography,
} from '@hexa-ui/components2';
import { AlertTriangle } from '@hexa-ui/icons2';
import { EmptyState } from '@hexa-ui/illustrations';
import { NotificationTabs } from 'components/Notification/constants';
import { timeSince } from 'components/Notification/utils/notificationContentUtils/notificationContentUtils';
import { useNotificationContext } from 'contexts';
import { useGetApps } from 'hooks/useGetApps';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  changeNotificationStatus,
  useLoadNotifications,
} from '../../../../components/Notification/services/notification/newLoadNotification/newLoadNotificationService';
import {
  Card,
  Container,
  EmptyStateText,
  List,
  MessageContainer,
  Notification,
  StatusButton,
  StatusContainer,
  TitleContainer,
  ToolContainer,
} from './LatestNotifications.styles';

export function LatestNotifications() {
  const { formatMessage, locale } = useIntl();
  const { setIsNotificationsDropdownOpen, setDefaultTab } =
    useNotificationContext();
  const [notifications, setNotifications] = useState([]);
  const { data: allApps } = useGetApps(locale);

  const {
    notifications: latestNotifications,
    isLoading,
    hasError,
    fetchNotifications,
  } = useLoadNotifications({ pageNumber: 0, pageSize: 10 });

  useEffect(() => {
    latestNotifications && setNotifications(latestNotifications);
  }, [latestNotifications]);

  const updateNotificationStatus = async (
    notificationId: string,
    read: boolean,
  ) => {
    const updatedNotifications = await changeNotificationStatus({
      notificationId,
      notifications: notifications,
      read,
    });
    if (updatedNotifications) {
      setNotifications(updatedNotifications);
    }
    return;
  };

  const getAppName = (notificationName: string) => {
    const regex = new RegExp(/^\[(.*?)\]/);
    const match = regex.exec(notificationName);

    const appId = match ? match[1] : null;

    if (!appId) return;

    return (
      allApps?.data?.content.find((app) => app.identifier === appId)?.name || ''
    );
  };

  const renderNotifications = () => {
    if (isLoading) {
      return (
        <Container>
          <LoadingSpinner size="xlarge" />
        </Container>
      );
    }
    if (hasError) {
      return (
        <Container>
          <EmptyState.SomethingWentWrong width="128px" height="128px" />
          <EmptyStateText>
            {formatMessage({ id: 'Notification.error' })}
          </EmptyStateText>
          <Button
            variant="secondary"
            onClick={() => {
              fetchNotifications();
            }}
          >
            {formatMessage({ id: 'Notification.reload' })}
          </Button>
        </Container>
      );
    }
    if (notifications?.length === 0) {
      return (
        <Container>
          <EmptyState.NoDataYet width="128px" height="128px" />
          <EmptyStateText>
            {formatMessage({ id: 'Notification.noNotifications' })}
          </EmptyStateText>
        </Container>
      );
    }
    return (
      <List>
        {notifications?.map((notification) => (
          <Notification key={notification.id}>
            <ToolContainer>
              <Typography.SubHeader style={{ margin: '0px 0px 4px' }}>
                {getAppName(notification.notificationName)}
              </Typography.SubHeader>
              <StatusContainer>
                <Tooltip
                  content={formatMessage({
                    id: notification.read
                      ? 'Notification.markUnread'
                      : 'Notification.markRead',
                  })}
                  position="right"
                >
                  <StatusButton
                    onClick={() =>
                      updateNotificationStatus(
                        notification.id,
                        notification.read,
                      )
                    }
                    read={notification.read}
                    data-testid="notification-status-button"
                  />
                </Tooltip>
                <Typography.Body style={{ margin: '0px', color: '#1414148f' }}>
                  {timeSince(notification.createdAt, locale, formatMessage)}
                </Typography.Body>
              </StatusContainer>
            </ToolContainer>
            <MessageContainer>
              <TitleContainer>
                <Typography.SubHeader style={{ margin: '0px' }}>
                  {notification.title}
                </Typography.SubHeader>
                {notification.priority && (
                  <Tooltip
                    content={formatMessage({
                      id: 'Notification.highPriority',
                    })}
                    position="top"
                  >
                    <div style={{ alignSelf: 'baseline' }}>
                      <Icon
                        icon={AlertTriangle}
                        style={{ color: '#D47300FF' }}
                      />
                    </div>
                  </Tooltip>
                )}
              </TitleContainer>
              <Typography.Body
                style={{ margin: '2px 0px 0px', color: '#1414148f' }}
              >
                {notification.formattedMessage}
              </Typography.Body>
              {!!notification?.inAppUrl && (
                <TextLink
                  href={notification.inAppUrl}
                  style={{ marginTop: '8px', width: 'fit-content' }}
                >
                  {formatMessage({ id: 'Notification.showMore' })}
                </TextLink>
              )}
            </MessageContainer>
          </Notification>
        ))}
      </List>
    );
  };

  return (
    <Card elevated="minimal" border="medium">
      <Typography.Header
        variant="h4"
        style={{ margin: '0px', padding: '0px 24px', lineHeight: '40px' }}
      >
        {formatMessage({ id: 'Notification.latestNotifications' })}
      </Typography.Header>
      {renderNotifications()}
      {notifications?.length > 0 && (
        <TextLink
          style={{
            padding: '16px 24px 0px',
            position: 'absolute',
            bottom: '20px',
          }}
          onClick={() => {
            setDefaultTab(NotificationTabs.allTab);
            setIsNotificationsDropdownOpen(true);
          }}
        >
          {formatMessage({ id: 'Notification.showAll' })}
        </TextLink>
      )}
    </Card>
  );
}

export default LatestNotifications;
